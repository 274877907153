import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Contact from "../components/contact"
import SectionNoBG from "../components/section_no_bg"
import TeamMembers from "../components/team_members"

import Fade from "react-reveal/Fade"

import introTexts from "../../content/team/intro_section.json"
import contactTexts from "../../content/landing_page/contact.json"

const Team = () => (
  <Layout pageTitle="The Team">
    <Seo
      title="The team"
      description="The team at Djäkne Startup Studio has people who are specialized in software development, product, financing, accounting, design and communications. Get to know our team."
    />
    <SectionNoBG
      narrow={true}
      classes="first-section"
      headline={introTexts.headline}
      text={introTexts.text}
    ></SectionNoBG>
    <Fade bottom duration={1500} fraction={0.1} distance={"200px"}>
      <TeamMembers id="team"></TeamMembers>
      <Contact texts={contactTexts} />
    </Fade>
  </Layout>
)

export default Team
