import React, { useEffect } from "react"
import "./section_image_bg.module.scss"
import "./section_image_grid.module.scss"
import "./team_members.module.scss"

import members from "../../content/team/members.json"

function TeamMembers(props) {
  const [randomPersons, setRandomPersons] = React.useState([]);

  useEffect(() => {
    setRandomPersons([...members.persons].sort(() => Math.random() - 0.5));
  }, []);

  return (
    <section id={props.id}
      className={
        props.classes ? `image-section ${props.classes}` : `image-section`
      }
    >
      <div className="container-fluid image-section-container text-white">
        <div className="image-slider">
          <div className="grid-container">
            {randomPersons.map((person, index) => (
              <div key={`${index}-${person.profilePicture}`}>
                <img src={person.profilePicture} alt={person.full_name} />
                <div className="emp-tags-container">
                  <p className="buttons-container mb-2">
                    {person.roles.map((role, i) => (
                      <span
                        key={i}
                        className="team-tag ms-1 mt-1"
                      >
                        {role}
                      </span>
                    ))}
                  </p>
                  <div className="texts-container">
                    <h5 className="mb-1">{person.full_name} </h5>
                    {person.email && (
                      <p className="flex-fill">
                        <a href={`mailto:${person.email}`}>{person.email}</a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TeamMembers
