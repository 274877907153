import * as React from "react"

import "./contact.module.scss"

import arrow from "../images/arrow.svg"

function Contact(props) {
  return (
    <section className="contact">
      <div className="container-fluid">
        <div className="container-xxl">
          <div className="row align-items-center ">
            <div className="col-12 d-flex flex-row ">
              <h1 className="mb-3 mb-md-0">{props.texts.section_headline}</h1>
            </div>
            <div className="d-flex flex-md-row flex-column mt-md-5 align-items-md-center">
              <div className="d-flex flex-column text-start justify-content-start pe-md-5 my-5">
                {props.texts.target_groups.map((group, index) => (
                  <button
                    key={`contact-flush-collapse${index}`}
                    href=""
                    className="btn btn-link text-left arrow-button large toggleColor py-2 py-md-3 me-sm-5 collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target={`#contact-flush-collapse${index}`}
                  >
                    <p className="mb-0 hover-show">{group.cta_text}</p>
                    <img alt="arrow" className="mx-3" src={arrow} width="36" />
                  </button>
                ))}
              </div>
              <div
                className=" flex-grow-0 accordion accordion-flush"
                id="accordionFlushExample-2"
              >
                {props.texts.target_groups.map((group, index) => (
                  <div
                    className="accordion-item"
                    key={`accordion-item${index}`}
                  >
                    <div
                      id={`contact-flush-collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample-2"
                    >
                      <div className="accordion-body p-3 p-md-4 p-lg-5 ">
                        <p className="mb-3">{group.text}</p>
                        <a
                          className="arrow-button toggleColor pb-2 pt-5"
                          href={`mailto:${group.email}?subject=${group.subject}`}
                        >
                          <p className="mb-0 hover-show">{group.email}</p>
                          <img
                            alt="arrow"
                            className="mx-3"
                            src={arrow}
                            width="36"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
